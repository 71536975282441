import axios from "../axios/axios-kaqadmin";
import {useBearerTokenHeaders} from "../utils/functions";

export const serviceChargeSerice = {
    get,
    sendToFData,
    update
}

function get(payload){
    return new Promise((resolve, reject) => {
        axios.post('/service-charge/get', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function update(payload){
    return new Promise((resolve, reject) => {
        axios.post('/service-charge/update', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function sendToFData(payload){
    return new Promise((resolve, reject) => {
        axios.post('/service-charge/approve', payload, useBearerTokenHeaders())
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));

    })
}
