<template>
  <!-- chat area -->
  <div class="col-xl-12 col-lg-12">
    <div class="border-bottom border-light">
      <div class="media py-1">
        <a href="javascript:void(0)" class="mr-2 mt-2"
           @click="onBackPressed"
        ><i class="fa fa-arrow-left fa-1x"></i></a>
        <b-avatar size="md" variant="dark" class="text-light mr-1" style="cursor: pointer" @click="onBackPressed"
        ><span class="">{{ driver.name | initials }}</span></b-avatar
        >
        <div class="media-body">
          <h5 class="mt-0 mb-0 font-15">
            <router-link to="/contacts/profile" class="text-reset">
              {{driver.name}} {{driver['other_names']}}
            </router-link>
          </h5>
          <p class="mt-1 mb-0 text-muted font-12">
<!--            <small class="mdi mdi-circle text-success"></small> -->
            {{driver['phone']}}
          </p>
        </div>

        <div
            class="card shadow-none border text-left"
        >
          <div class="p-2">
            <div class="row align-items-center">
              <div class="col-auto">
                <div class="avatar-sm">
                              <span class="avatar-title bg-primary rounded"
                              >IMG</span
                              >
                </div>
              </div>
              <div class="col pl-0">
                <p class="mb-0">{{file['id']}}</p>
                <div
                    class="text-muted font-weight-medium"
                >{{file['createdAt']}}</div
                >
              </div>
              <div class="col-auto">
                <!-- Button -->
                <a
                    :href="file['filePath']"
                    target="_blank" download
                    class="btn btn-link btn-lg text-muted"
                >
                  <i class="fas fa-download"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="pt-4">
      <div v-if="url" style="max-height: 589px; background: white">
        <div class="mb-3 text-light">
          <h4>
            <i
                @click="url = null"
                class="fas fa-times mr-5"
            /><span class="font-weight-bold">Preview</span>
          </h4>
        </div>
        <div v-if="progress>0"
             class="progress mb-2"
             style="height: 15px; border-radius: 0"
        >
          <div
              class="progress-bar bg-orange"
              role="progressbar"
              :style="{ width: progress + '%' }"
              :aria-valuenow="progress"
              aria-valuemin="0"
              aria-valuemax="100"
          >
                          <span class="progress-indicator"
                          >Upload is  {{progress.toFixed(2) }} % done</span
                          >
          </div>
        </div>

        <div id="preview" class="mb-3" style="display: flex; justify-content: center; align-items: center;">
          <img class="img-fluid" style="max-height: 387px;"
               v-if="url && url.type === 'image'"
               :src="url.url"
          />
          <div v-if="url && url.type !== 'image'">
            <i
                style="font-size: 10rem"
                class="fas fa-file-alt mt-5"
            />
          </div>
        </div>
        <div class="text-center">{{url.name}}</div>

      </div>

      <simplebar data-simplebar style="max-height: 460px" id="scrollID" v-else>
        <ul class="conversation-list chat-app-conversation" ref="chatContent"  >
          <li
              class="clearfix"
              v-for="(data, index) in chatMessagesData"
              :key="index"
              :class="{ odd: data.sentBy === 'admin' }"
          >
            <div class="chat-avatar">
              <b-avatar size="md" variant="light" class="text-dark mr-1" style="cursor: pointer" @click="onBackPressed"
              >
                <template v-if="data.sentBy === 'admin'">
                  <span class="">{{ admin.name | initials }}</span>
                </template>
                <template v-else>
                  <span class="">{{ driver.name | initials }}</span>
                </template>


              </b-avatar
              >
            </div>
            <div class="conversation-text">
              <div class="ctext-wrap">
                <b-dropdown
                    class="conversation-actions" :class="{ 'bg-light':data.sentBy === 'admin' }"
                    toggle-class="btn-sm btn-link text-reset p-0"
                    variant="black"
                    right
                >
                  <template v-slot:button-content>
                    <i class="fa fa-chevron-down font-18"></i>
                  </template>
                  <button class="dropdown-item" @click="getReply(data)">Reply</button>
                  <button class="dropdown-item" v-if="data.sentBy === 'admin'" @click="deleteChatMessage(data)">Delete</button>
                </b-dropdown>
                <div class="message-content">
                  <div class="_3Ppzm" v-if="data.reply">
                    <div style="font-size: 10px">
                      {{data.sentBy === 'admin' ? "You" : ((data || {}).reply || {}).name}}
                    </div>
                    <div v-if="data.reply.message">{{ ((data || {}).reply || {}).message }}</div>
                    <div v-if="data.reply.hasFile">
                      <div>{{data.reply.file.name}}</div>
                    </div>

                  </div>
                  <div v-if="data.file">
                    <audio
                        controls
                        v-if="data.file.type === 'mp3'"
                    >
                      <source
                          :src="data.file.url"
                          type="audio/ogg"
                      />
                      <source
                          :src="data.file.url"
                          type="audio/mpeg"
                      />
                    </audio>
                    <video
                        v-if="data.file.type === 'mp4'"
                        style="height: 500px"
                        class="w-100"
                        controls
                        controlsList="nodownload"
                    >
                      <source
                          :src="data.file.url"
                          type="video/mp4"
                      />
                      <source
                          :src="data.file.url"
                          type="video/ogg"
                      />
                    </video>
                    <div v-if="getFileType(data.file.type) ==='image'">
                      <img
                          :src="data.file.url"
                          class="img-fluid"
                          style="width: 330px; max-height: 248px; object-fit: contain;"
                          alt=""
                      />
                      <div
                          class="text-muted font-weight-medium"
                      >{{ data.file.name }}</div
                      >
                    </div>

                    <div
                        class="card mt-2 mb-1 shadow-none border text-left"
                        v-else
                    >
                      <div class="p-2">
                        <div class="row align-items-center">
                          <div class="col-auto">
                            <div class="avatar-sm">
                              <span class="avatar-title bg-primary rounded"
                              >{{ data.file.type }}</span
                              >
                            </div>
                          </div>
                          <div class="col pl-0">
                            <a
                                class="text-muted font-weight-medium"
                            >{{ data.file.name }}</a
                            >
                            <p class="mb-0">{{ data.file.size | fileSize }}</p>
                          </div>
                          <div class="col-auto">
                            <!-- Button -->
                            <a
                                :href="data.file.url"
                                class="btn btn-link btn-lg text-muted"
                                target="_blank"
                                :download="data.file.name"
                            >
                              <i class="fas fa-download"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <p><i>{{ data.message }}</i></p>
                <small>{{ formatTimestamp(data.sentAt) }}</small>
              </div>
              <div
                  class="card mt-2 mb-1 shadow-none border text-left"
                  v-if="data.file === true"
              >
                <!--                <div class="p-2">-->
                <!--                  <div class="row align-items-center">-->
                <!--                    <div class="col-auto">-->
                <!--                      <div class="avatar-sm">-->
                <!--                              <span class="avatar-title bg-primary rounded"-->
                <!--                              >PDF</span-->
                <!--                              >-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                    <div class="col pl-0">-->
                <!--                      <a-->
                <!--                          href="javascript:void(0);"-->
                <!--                          class="text-muted font-weight-medium"-->
                <!--                      >minton-presentation.pdf</a-->
                <!--                      >-->
                <!--                      <p class="mb-0">2.3 MB</p>-->
                <!--                    </div>-->
                <!--                    <div class="col-auto">-->
                <!--                      &lt;!&ndash; Button &ndash;&gt;-->
                <!--                      <a-->
                <!--                          href="javascript:void(0);"-->
                <!--                          class="btn btn-link btn-lg text-muted"-->
                <!--                      >-->
                <!--                        <i class="dripicons-download"></i>-->
                <!--                      </a>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
<!--            <b-dropdown-->
<!--                class="conversation-actions"-->
<!--                toggle-class="btn-sm btn-link text-reset p-0"-->
<!--                variant="black"-->
<!--                right-->
<!--            >-->
<!--              <template v-slot:button-content>-->
<!--                <i class="mdi mdi-dots-vertical font-18"></i>-->
<!--              </template>-->
<!--              <a class="dropdown-item" href="#">Copy Message</a>-->
<!--              <a class="dropdown-item" href="#">Edit</a>-->
<!--              <a class="dropdown-item" href="#">Delete</a>-->
<!--            </b-dropdown>-->
          </li>
        </ul>
      </simplebar>

<!--    Button to send message  -->
      <div class="row">
        <div class="col">
          <div class="mt-2 bg-light p-3 rounded">
            <div style="display: flex" v-if="reply">
              <div class="_3Ppzm _3OUXw" style="flex: 1">
                <div class="_26iqs">
                  <small class="reply_phone text-green">{{reply.senderName}}</small>
                </div>
                <div class="_31DtU">
                  <span class="reply-text font-weight-bold" v-if="reply.message">{{ reply.message }}</span>
                  <div v-if="reply.hasFile">
                    <div>{{reply.file.name}}</div>
                  </div>
                </div>
              </div>
              <div
                  class="UUcHl"
                  style="margin-top: 5px; margin-left: 20px"
              >
                <div role="button" @click="reply = ''">
                          <span data-testid="x" data-icon="x" class=""
                          ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                          >
                              <path
                                  fill="currentColor"
                                  d="M19.1 17.2l-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"
                              /></svg
                          ></span>
                </div>
              </div>
            </div>

            <form
                class="needs-validation"
                @submit.prevent=""
                name="chat-form"
                id="chat-form"
            >
              <div class="row">
                <div class="col mb-2 mb-sm-0">
                  <input
                      type="text"
                      v-model="form.message"
                      ref="send"
                      class="form-control border-0"
                      placeholder="Enter your text"
                  />
                  <div
                      v-if="submitted && $v.form.message.$error"
                      class="invalid-feedback"
                  >
                          <span v-if="!$v.form.message.required"
                          >Please enter your message</span
                          >
                  </div>
                </div>
                <div class="col-sm-auto">
                  <div class="btn-group">
                    <button @click="triggerFile" type="button" class="btn btn-light" v-if="chatUser">
                      <i class="fe-paperclip"></i>
                    </button>
                    <button
                        type="submit"
                        @click="formSubmit"
                        class="btn btn-success chat-send btn-block"
                    >
                      <i class="fe-send"></i>
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row-->
            </form>

          </div>
        </div>
        <!-- end col-->
      </div>
    </div>
    <input
        id="file-select"
        ref="fileSelect"
        style="visibility: hidden"
        type="file"
        @change="selectFile"
    />
    <!-- end card -->
  </div>
  <!-- end chat area-->
</template>

<script>
import {getDatabase, ref, set, onValue, push, update, get, remove} from "firebase/database";
import moment from 'moment';
import {chatItem, chatUserItem, getFileType} from "../../../../utils/functions";
import {deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytesResumable} from "firebase/storage";

export default {
  name: "chat-on-receipts",
  props: ['driver', 'file'],
  data(){
    return {
      chatMessagesData: [],
      submitted: false,
      form: {
        message: "",
      },
      username: "Designer",
      chatUser: this.driver,
      reply:null,
      fileUpload: "",
      url: null,
      progress: 0,
      disableMsgSendBtn: false,
    }
  },
  computed:{
    admin() {
      return this.$store.getters.GET_USER_INFO
    },
  },
  methods: {
    getFileType,
    onBackPressed(){
      this.$emit('onBackPressed');
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = document.querySelector('#scrollID .simplebar-content-wrapper');
        if(!container){
          return;
        }
        container.scrollTo({ top: 10000000000000000000000, behavior: "smooth" });
      })
    },

    getReply(item) {
      this.reply = item;
      this.$refs.send.focus();
    },

    triggerFile() {
      this.$refs.fileSelect.value = null;
      this.$refs.fileSelect.click();
    },

    selectFile(e) {
      this.fileUpload = e.target.files[0];
      this.url = {
        url: "",
        type: "",
        name:""
      };
      let fileType = this.fileUpload.name.split(".").pop();
      const fileSizeInBytes = this.fileUpload.size;
      this.url.name = this.fileUpload.name;
      this.url.size = fileSizeInBytes;


      if (fileType === "jpeg" || fileType === "png" || fileType === "jpg") {
        this.url.url = URL.createObjectURL(this.fileUpload);
        this.url.type = "image";
      } else {
        this.url.type = fileType;
        this.url.url = "../../../public/assets2/img/clip.png";
      }
    },

    fetchChats(){
      const db = getDatabase();
      const chatRef = ref(db, `receipts/${this.driver.id}/${this.file.id}`);
      onValue(chatRef, (snapshot) => {
        const data = snapshot.val();

        // console.log('snapshot data', data)
        if(!data){
          this.chatMessagesData =[];
          return;
        }

        let arr = [];


        for (let key in data) {
          if (data.hasOwnProperty(key)) {

            let chat = data[key];
            chat.key = key;

              arr.push(chat)

              if(chat['sentTo'] === 'admin'  && chat['read'] === false ){
                const updateData = {
                  'recipientId': this.admin.id,
                  'recipientName': this.admin.name,
                  'read': true
                }
                const upRef = ref(db, 'receipts/' + this.driver.id + '/' + key)
                //update(upRef, updateData);
              }

          }
        //
        //
        }

        arr.sort(function(a, b){return a['sentAt']-b['sentAt']})
        console.log("chat data",arr);
        this.chatMessagesData = arr;
        // console.log('chatMessagesData', this.chatMessagesData)
        this.scrollToBottom();

      });
    },

    formSubmit() {
      if(!this.url){
        if(this.form.message === ""){
          return;
        }
        this.saveMessage();
      }else{
        this.uploadFile();
      }

    },

    async uploadFile() {
      // Create a reference to the Firebase Storage
      this.disableMsgSendBtn = true;
      const firebaseStorage = getStorage();
      try {
        // Create a child reference inside 'users' with the driver ID and file name
        const fileRef = storageRef(firebaseStorage, `receiptChatFiles/${this.fileUpload.name}`);

        // Upload the file to Firebase Storage using uploadBytesResumable
        const uploadTask = uploadBytesResumable(fileRef, this.fileUpload);

        // Listen for state changes and progress updates
        uploadTask.on('state_changed',
            snapshot => {
              // Get the progress (percentage) of the upload
              this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              // console.log('Upload is ' + progress.toFixed(2) + '% done');
            },
            error => {
              console.error("Error uploading file:", error);
            },
            async () => {
              try {
                // Upload completed successfully, get the download URL
                const downloadURL = await getDownloadURL(fileRef);

                // console.log('File uploaded successfully. Download URL:', downloadURL);
                this.saveMessage(downloadURL)


              } catch (error) {
                console.error("Error getting download URL:", error);
              }
            }
        );
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },


    saveMessage(fileUrl=''){
      let fileDetails=''

      if(fileUrl){
        fileDetails = {
          type: this.url.type,
          url: fileUrl,
          name: this.url.name,
          size: this.url.size
        }
      }
      const db = getDatabase();

      const chatRef = ref(db, `receipts/${this.driver.id}/${this.file.id}`);
      const newChatRef = push(chatRef);

      console.log('file on submit', this.file);

      const driverName = this.driver.name + " " + this.driver['other_names'];
      const item = chatItem(this.form.message, this.driver.id, driverName, 'receipt', this.file['chat_id'],this.reply,fileDetails)

      set(newChatRef, item).then(() => {
        // Data saved successfully!
        this.form.message = ''
        this.scrollToBottom()
        this.updateDriverUser(item['message'], item['sentAt'])
      }).catch((error) => {
      // The write failed...
      });

      this.reply ='';
      this.disableMsgSendBtn = false;
      this.url=null;
      this.progress =0;

    },

    deleteChatMessage(data){
      // Create a reference to the Firebase Realtime Database
      let deleteConfirmation = confirm('Are you sure you want to delete this chat message?');
      if (deleteConfirmation){
        const db = getDatabase();

        // Create a reference to the chat message you want to delete
        const chatRef = ref(db, `receipts/${this.driver.id}/${this.file.id}/${data.key}`);

        // Delete the chat message from the Realtime Database
        remove(chatRef)
            .then(() => {
              // If successful, the message will be removed from the Realtime Database.
              console.log("Chat message deleted successfully!");
            })
            .catch(error => {
              console.error("Error deleting chat message:", error);
            });
        if(data.hasFile){
          this.deleteFile(data.file.name);
        }
      }

    },

    async deleteFile(fileName) {
      const firebaseStorage = getStorage();
      try {
        // Get the reference to the file in Firebase Storage using the download URL
        const fileRef = storageRef(firebaseStorage, 'receiptChatFiles/'+ fileName);
        await this.deleteFileFromStorage(fileRef);

      } catch (error) {
        console.error('Error deleting file:', error);
      }
    },

    async deleteFileFromStorage(fileRef) {
      try {
        // Delete the file from Firebase Storage
        await deleteObject(fileRef);

        console.log('File deleted successfully.');
      } catch (error) {
        console.error('Error deleting file from Storage:', error);
      }
    },

    formatTimestamp(value){
      return moment(value).calendar();
    },


  //  chats area
    setupDriverChat(driver) {

      const db = getDatabase();

      const userRef = ref(db, 'users/' + driver.id);

      const data =  chatUserItem(driver, 'receipt');

      // check if user ref has already not been created
      // if not, create a chat channel for user
      get(userRef).then((snapshot) => {

        console.log('setupDriverChat called', this.chatUser)

        if (!snapshot.exists()) {
          set(userRef, data).then(() => {
            this.chatUser = data;
          }).then(() => {
            this.searchMode = false
          })
        }else{
          this.updateAdminPendingMessages()
        }

      }).catch((error) => {
        console.error(error);
      });

    },

    updateAdminPendingMessages(newValue = 0){
      console.log('updateAdminPendingMessages called', this.chatUser)
      if(!this.chatUser)return;

      const db = getDatabase();
      const userRef = ref(db, 'users/' + this.chatUser.id);
      const updateData = {
        'pendingReceiptMessagesForAdmin': newValue,
      }
      update(userRef, updateData);
    },

    updateDriverUser(lastMessage, lastMessageTime){
      if(!this.chatUser)return;

      const db = getDatabase();
      const userRef = ref(db, 'users/' + this.chatUser.id);

      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {

          const map = snapshot.val();
          let pendingSupportMessagesForDriver = map['pendingReceiptMessagesForDriver'];
          pendingSupportMessagesForDriver = pendingSupportMessagesForDriver + 1;

          const updateData = {
            'lastMessage': lastMessage,
            'lastMessageTime': lastMessageTime,
            'pendingReceiptMessagesForDriver': pendingSupportMessagesForDriver,
          }

          update(userRef, updateData);

        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });

      this.updateAdminPendingMessages();

    },

  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setupDriverChat(this.driver)
    this.fetchChats();

    // this.scrollToBottom()
  }
}
</script>

<style scoped>
.conversation-actions{
  position: absolute;
  background: #d5eef7;
  z-index: 1;
  right: 5px;
}
._3Ppzm {
  background: #d5eef7;
  border-left: 4px solid #0599a9;
  border-radius: 5px;
  padding: 8px 12px 9px 11px;
  margin-bottom: 10px;
}
</style>
