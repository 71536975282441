<template>
  <div class="row">
    <div class="col-12">

      <div class="mb-2">
        <button class="btn btn-outline-danger" @click="sendToFData"> Click here manually move all service charges for week {{weekNumber}}, {{yearNumber}} to financial data</button>
      </div>

      <div class="card">
        <div class="card-body">
          <Sidepanel @onDriverSelected="onDriverSelected" />
          <div class="inbox-rightbar">
            <!--              <Toolbar />-->

            <template v-if="selectedDriver">

              <div class="mt-0">
                <h5 class="font-18">
                  <span class="mr-3">{{selectedDriver['name']}} {{selectedDriver['other_names']}} </span>
                </h5>
                <ul>
                  <li><span class="text-danger ">Service charges apply only when a service type is selected for driver</span></li>
                  <li><span class="text-danger">Service charges are generated weekly </span></li>
                  <li>
                    These values are automatically generated at the beginning of every week and automatically saved to financial data during payouts.
                    However, admin can manually edit the values with the form below, and manually move all data to financial administration with the "red button above"
                  </li>
                </ul>
              </div>

              <hr>
              <span class="mr-2">Selected service:</span>
              <template v-if="selectedDriver['prospective_driver'] && selectedDriver['prospective_driver']['service_type']">
               <span class="text-success"> {{selectedDriver['prospective_driver']['service_type'] | serviceType}} </span>
              </template>
              <template v-else>
               <span class="text-danger"> No service selected </span>
              </template>
              <hr>
              <div class="mt-4">


                <template v-if="statusDisplay">
                  <span class="text-danger"> {{statusDisplay}} </span>
                </template>

                <hr>
                <form action="#" @submit.prevent="handleSubmit">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Week</label>
                          <input type="week" class="form-control" v-model="startDate" @change="fetchServiceCharges">
                        </div>
                      </div>
                     <template v-if="generated">
                       <div class="col-md-6">
                         <div class="form-group">
                           <label>Charge in €</label>
                           <input type="text" class="form-control" :disabled="disable" v-model="charge">
                         </div>
                       </div>
                       <div class="col-md-6">
                         <div class="form-group">
                           <label>Tax in €</label>
                           <input type="text" class="form-control" disabled v-model="tax">
                         </div>
                       </div>
                     </template>
                    </div>
                    <div class="row" v-if="generated">
                      <div class="col-md-12">
                        <div class="form-group">
                          <button class="btn btn-primary" type="submit" v-if="!disable"> <i class="fa fa-save"></i> Update service charge </button>
                        </div>
                      </div>
                    </div>
                  </form>

              </div>


            </template>

          </div >

        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->
    </div>
  </div>
</template>

<script>

import Sidepanel from "./sidepanel";
import ChatOnReceipts from "../receipts/chat-on-receipts"


import appConfig from "../../../../../app.config";
import moment from "moment";
import {serviceChargeSerice} from "../../../../apis/service.charge.serice";
import {confirm} from "../../../../utils/functions";

/**
 * Read-email component
 */
export default {
  components: {

    Sidepanel,
    ChatOnReceipts
  },
  data() {
    return {

      charge: '',
      tax: '',
      selectedDriver: null,
      status: 'pending',
      startDate: '',
      statusDisplay: '',
      weekNumber: '',
      yearNumber: '',
      disable: false,
      id: '',
      generated: false

    };
  },
  watch: {
    selectedDriver: function (driver) {
      if(driver){
        this.fetchServiceCharges();
      }
    }
  },
  methods: {

    handleSubmit(){

      if(!this.selectedDriver){
        this.$store.dispatch('error', {message: 'Please select a driver', showSwal: true})
        return;
      }

      confirm('Update service charge for ' + this.selectedDriver['name'] + ' ' + this.selectedDriver['other_names'], () => {
        const payload = {
          'id': this.id,
          'driver_id' : this.selectedDriver['id'],
          'week': this.startDate,
          'charge': this.charge,
        }

        this.$store.dispatch('showLoader')
        serviceChargeSerice.update(payload).then((data) => {
          this.$store.dispatch('hideLoader')
          this.setData(data)
          const message = 'Update service charge for ' + this.selectedDriver['name'] + ' ' + this.selectedDriver['other_names'];
          this.$store.dispatch('success', {message: message, showSwal: true})
        })
      })

    },

    setData(data){
      const sc = data['extra']['data']
      this.statusDisplay = data['extra']['statusDisplay']
      this.startDate = data['extra']['week']
      this.weekNumber = data['extra']['weekNumber']
      this.yearNumber = data['extra']['year']
      this.generated = data['extra']['generated']

      if(sc){
        this.id = sc['id']
        this.disable = sc['status'] === 'approved'
        const field =  JSON.parse(sc['calculated_with_tax_fields']);
        console.log(field)
        this.charge = field['fieldDefaultValue']
        this.tax = field['tax']['value']
      }

    },


    sendToFData(){
      const message =  'Manually send week ' + this.weekNumber + ', ' + this.yearNumber + ' to financial data';
      confirm(message, () => {
        const payload = {
          'driver_id' : this.selectedDriver['id'],
          'week': this.startDate,
        }

        this.$store.dispatch('showLoader')
        serviceChargeSerice.sendToFData(payload).then((data) => {
          this.$store.dispatch('hideLoader')
          this.setData(data)
          const message = 'Service charge for week ' + this.weekNumber + ', ' + this.yearNumber + ' sent to financial data';
          this.$store.dispatch('success', {message: message, showSwal: true})
        })
      })
    },

    onDriverSelected(driver){
      this.selectedDriver = driver
    },

    fetchServiceCharges(){
        if(!this.selectedDriver){
          this.$store.dispatch('error', {message: 'Please select a driver', showSwal: true})
          return;
        }

        this.$store.dispatch('showLoader')
        const payload = {
          'driver_id': this.selectedDriver['id'],
          'week': this.startDate
        }
        serviceChargeSerice.get(payload).then((data) => {
          this.$store.dispatch('hideLoader')
          this.setData(data)
        })
    }

  },
  created() {
    this.$store.dispatch('getExternalDataConfigFields')
  },
};
</script>

